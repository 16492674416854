import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';
import { resizeObserverHandler } from './util/resizeObserverHandler.js';
/**
* `ai-menu`
* 
* 
* @customElement
* @polymer
*/
class AiMenu extends resizeObserverHandler(PolymerElement) {
	static get is(){ return 'ai-menu'; }
	static get template() {
	return html`
		<style>
			:host {
				z-index:100;
				position: relative;
				display: block;
			}
			div{
				display: none;
				
				flex-direction:var(--ai-menu-flex-direction, row);
				justify-content:var(--ai-menu-justify-content, space-between);
				align-items: var(--ai-menu-align-items, center);
			}
			:host([sm]) div{
				display: none;
			}
			:host([med]) div{
				display:flex;
				font-size:.8em;
			}
			:host([lg]) div{
				
				font-size:1em;
			}
		</style>
		<nav>
			<div role='list'>
				<slot></slot>
			</div>
		</nav>
		`;
	}
	static get properties() {
		return {
			version : {
				type : String,
				value : '0.1',
				readonly : true
			},
			prop1 : {
				type : String,
				value : 'ai-menu',
			},
			breakpoints : {
				type : Object,
				value : {
					lg : 700,
					med : 500,
					sm : 300
				}
			}
		};
	}
	constructor(){
		super();
		this.removeAttribute('unresolved');
	}

	ready(){
		super.ready();
	}
}

window.customElements.define(AiMenu.is, AiMenu);
