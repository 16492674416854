import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';
import { reveals } from './util/revealsHelper.js';
/**
* `ai-submenu`
* 
* 
* @customElement
* @polymer
*/
class AiSubmenu extends reveals(PolymerElement){
	static get is(){ return 'ai-submenu'; }
	static get template() {
	return html`
			<style>
				*{
					box-sizing: border-box;
				}
				:host, div {
					margin: 0;
					display: block;
					line-height: 1.2;
					padding: 0;
					position: absolute;
					top:100%;
					left: 0;
					min-width: 100%;
					background:var(--ai-sub-menu-background, hsla(230,15%,90%,1));
					color:var(--ai-sub-menu-text-color, currentColor);
					box-shadow: var(--ai-sub-menu-box-shadow, 1px 3px 1px -2px rgba(0,0,0,.15), 0px 3px 4px rgba(0,0,0,.1));
				}

				:host([closed]) div{
					display: none;
				}
				:host([opened]) div{
					display: block;
				}
				
			</style>
			<div role='list'>
				<slot></slot>
			</div>
			`;
	}
	static get properties() {
		return {
			version : {
				type : String,
				value : '0.1',
				readonly : true
			}
		};
	}
	constructor(){
		super();
		this.removeAttribute('unresolved');
	}

	ready(){
		super.ready();
	}
}

window.customElements.define(AiSubmenu.is, AiSubmenu);
