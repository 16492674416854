import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';
import {commandQueueConsume} from './util/commandQueue.js';
/**
* `ai-resize-pubsub`
* 
* 
* @customElement
* @polymer
* @demo demo/index.html
*/
class AiResizePubsub extends PolymerElement {
    static get is(){ return 'ai-resize-pubsub'; }
    static get template() {
    return html`
        
            
            `;
        }
        static get properties() {
            return {
                version : {
                    type : String,
                    value : '0.1',
                    readonly : true
                },
                resizeMonitor : {
                    type : Boolean,
                    value : true,
                    reflectToAttribute : true
                },
                _commandQueueNamespace : {
                    type : String,
                    value : 'ai-resize-pubsub',
                    readonly : true
                }
            };
        }
    constructor(){
        super();
        this._resizeObserver;
        this._subscribers = new WeakMap();
        this._bindToSelf();
    }

    _bindToSelf(){
        super._bindToSelf && super._bindToSelf();
        this._resizeObserved = this._resizeObserved.bind(this);
        this._subscribe = this._subscribe.bind(this);
        this._enqueued = [];
    }

    connectedCallback(){
        this._consumeQueue();
        this._fireInitEvent();
        
        this._resizeObserver = new ResizeObserver(this._resizeObserved);
        if(this._enqueued.length > 0){
            this._enqueued.map(({element, callback}) => this._subscribe(element, callback));
            this._enqueued = [];
        }
    }

    ready(){
        super.ready();
    }
    
    _fireInitEvent(){
        const evt = new CustomEvent('resizepubsubinit', {
            detail : {
                element : this
            },
            bubbles : true,
            composed : true
        });
        this.dispatchEvent(evt);
    }

    _consumeQueue(){
        // this._queueConsumptionHandler = this._queueConsumptionHandler.bind(this);
        commandQueueConsume(this._commandQueueNamespace, (resolve, reject, element, callback) => {
            this._subscribe(element, callback);
            resolve(this);
        });
    }

    // _queueConsumptionHandler(){

    // }

    _resizeObserved(entries, observer){
        entries.forEach((entry) => {
            if(this._subscribers.get(entry.target)){
                this._subscribers.get(entry.target).forEach((subscriber) => {
                    this._notify(subscriber, entry);
                });
            }
        });
    }

    _subscribe(element, callback){
        if(! this._resizeObserver){
            this._enqueued.push({element, callback});
            return;
        }
        var notifications = this._subscribers.get(element) || [];
        if(notifications.length == 0){
            this._resizeObserver.observe(element);
        }
        notifications.push(callback);
        this._subscribers.set(element, notifications);
    }

    _notify(sub, entry){
        // const callbacks = this._subscribers.get(sub);
        // console.log(callbacks);
        const size = entry.contentRect;
        // for(let callback of callbacks){
            sub.call(null, size);
        // }
    }
}

window.customElements.define(AiResizePubsub.is, AiResizePubsub);
