import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';
import { dispatchEvent } from './util/eventsHelper.js';
/**
* `ai-map-location`
* 
* 
* @customElement
* @polymer
*/
class AiMapLocation extends PolymerElement {
    static get is(){ return 'ai-map-location'; }
    static get template() {
    return html`
        <style>
            :host {
                display: block;
            }
            </style>
            `;
        }
        static get properties() {
            return {
                version : {
                    type : String,
                    value : "0.4.1",
                },
                uId: {
                    type : Number,
                    reflectToAttribute :true
                },
                // _location : {
                //     type : Object,
                //     value: new AiLocation(this.lat, this.lng),
                //     notify: true
                // },
                lat: {
                    type : Number,
                    // computed: 'computeLat(_location)',
                    // observer : 'latChanged',
                    reflectToAttribute : true
                },
                lng: {
                    type : Number,
                    // computed: 'computeLng(_location)',
                    // observer : 'lngChanged',
                    reflectToAttribute : true
                },
                hoverIconUrl: {
                    type : String,
                    observer : '_hoverIconUrlChanged',
                    reflectToAttribute : true
                },
                hovered : {
                    type : Boolean,
                    observer : '_hoveredChanged',
                    reflectToAttribute : true
                },
                iconUrl : {
                    type : String,
                    observer : '_iconUrlChanged',
                    reflectToAttribute : true
                },
                labelOriginX : {
                    type : Number,
                    observer : '_labelOriginXChanged',
                    reflectToAttribute : true
                },
                labelOriginY : {
                    type : Number,
                    observer : '_labelOriginYChanged',
                    reflectToAttribute : true
                },
                labelText : {
                    type : String,
                    observer : '_labelTextChanged',
                    reflectToAttribute : true
                },
                labelColor : {
                    type : String,
                    observer : '_labelColorChanged',
                    reflectToAttribute : true
                },
                anchorX : {
                    type : Number,
                    observer : '_anchorXChanged',
                    reflectToAttribute : true
                },
                anchorY : {
                    type : Number,
                    observer : '_anchorYChanged',
                    reflectToAttribute : true
                },
                labelFontWeight: {
                    type : String,
                    observer : '_labelFontWeightChanged',
                    reflectToAttribute : true
                },
                icon: {
                    type : Object,
                    // value : {}
                    value : () => { return undefined; }
                },
                label:{
                    type : Object,
                    // value : {}
                    value : () => { return undefined }
                }
                // label: {
                //     computed : '_computeLabel(labelText, labelColor)'
                // },
                // icon: {
                //     computed : '_computeIcon(iconUrl, labelPositionX, labelPositionY)'
                // }
            };
        }
    constructor(){
        super();
        // this._location = new AiLocation();
        this.markerEvents = {
            mouseover : this._markerMouseOverHandler,
            mouseout : this._markerMouseOutHandler,
            click : () => {}
        };
    }
    // static get observers() {
    //     return [
    //         "_updatePropTracking(iconUrl, hoverIconUrl, anchorX, anchorY)"
    //     ];
    // }
    // _updatePropTracking(iconUrl, hoverIconUrl, anchorX, anchorY){
    //     console.log('iconUrl', iconUrl);
    //     console.log('hoverIconUrl', hoverIconUrl);
    //     console.log('anchorX', anchorX);
    //     console.log('anchorY', anchorY);
    // }

    _notifyChanged(){
        dispatchEvent('locationpropertychange', this, {uId : this.uId});        
    }

    _iconUrlChanged(newVal, oldVal){
        this.icon = this.icon || {};
        this.icon.url = newVal;
        this._notifyChanged();
    }
    _hoverIconUrlChanged(newVal, oldVal){
        this._notifyChanged();
    }
    _labelOriginXChanged(newVal, oldVal){
        this.icon = this.icon || {};
        if(this.icon.labelOrigin === undefined)
            this.icon.labelOrigin = {x:0,y:0};
        this.icon.labelOrigin.x = +newVal;
        this._notifyChanged();
    }
    _labelOriginYChanged(newVal, oldVal){
        this.icon = this.icon || {};
        if(this.icon.labelOrigin === undefined)
            this.icon.labelOrigin = {x:0,y:0};
        this.icon.labelOrigin.y = +newVal;
        this._notifyChanged();
    }
    _labelTextChanged(newVal, oldVal){
        this.label = this.label || {};
        this.label.text = newVal;
        this._notifyChanged();
    }
    _labelColorChanged(newVal, oldVal){
        this.label = this.label || {};
        this.label.color = newVal;
        this._notifyChanged();
    }
    _anchorXChanged(newVal, oldVal){
        this.icon = this.icon || {};
        if(this.icon.anchor === undefined)
            this.icon.anchor = {};
        this.icon.anchor.x = newVal;
        this._notifyChanged();
    }
    _anchorYChanged(newVal, oldVal){
        this.icon = this.icon || {};
        if(this.icon.anchor === undefined)
            this.icon.anchor = {};
        this.icon.anchor.y = newVal;
        this._notifyChanged();
    }

    // _computeIcon(url, labelOriginX, labelOriginY){
    //     return {
    //         url,
    //         labelOrigin : {
    //             x : +labelOriginX,
    //             y : +labelOriginY
    //         }
    //     }
    // }
    // _computeLabel(text, color){
    //     return {
    //         text,
    //         color
    //     }
    // }
    
    _hoveredChanged(newVal, oldVal){
        this.dispatchEvent(new CustomEvent('hoverstatechanged',{
            detail: {
                hovered : newVal
            },
            bubbles: true,
            composed: true
        }));
    }

    _markerMouseOverHandler(evt){
        this.hovered = true;
    }
    _markerMouseOutHandler(evt){
        this.hovered = false;
    }

    latChanged(newVal, oldVal){
        this._location.lat = newVal;
        this._notifyChanged();
    }
    lngChanged(newVal, oldVal){
        this._location.lng = newVal;
        this._notifyChanged();
    }
    
}

window.customElements.define(AiMapLocation.is, AiMapLocation);
