import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';
import { commandQueueConsume } from './util/commandQueue.js';
import { reveals } from './util/revealsHelper.js';
/**
* `ai-menu-drawer`
* 
* 
* @customElement
* @polymer
*/
class AiMenuDrawer extends reveals( PolymerElement ) {
    static get is(){ return 'ai-menu-drawer'; }
    static get template() {
    return html`
        <style>
            *{
                box-sizing:border-box;
            }
            :host {
                display: block;
                box-sizing:border-box;
                font-size:1em;
                font-weight:700;
                --_hue:var(--ai-menu-drawer-primary-color-hue, var(--ai-primary-color-hue, 210));
                --_saturation:var(--ai-menu-drawer-primary-color-saturation, var(--ai-primary-color-saturation, 15%));
                --_lightness:var(--ai-menu-drawer-primary-color-lightness, var(--ai-primary-color-lightness, 80%));
                /* --_hue2:var(--ai-menu */
                
            }
           
            #mobile_nav_overlay{
                position: fixed;
                top:0;
                left:0;
                width:100%;
                height: 100vh;
                background:var(--ai-menu-drawer-overlay-background, rgba(0,0,0,.4));
                z-index: 999;
                opacity:0;
                display: none;
                transition:opacity .3s;
            }
            #mobile_nav_container{
                width:400px;
                height: calc(100% - var(--ai-menu-drawer-top, 0px));;
                
                max-width: 90%;
                position: fixed;
                top:var(--ai-menu-drawer-top, 0);
                right:0;
                transform:translateX(100%);
                transition:transform .4s;
                transition-timing-function:cubic-bezier(0,0,.3,1);
                will-change: transform;
                z-index: 1000;
                overflow-y:auto;
            }
            #mobile_nav_container.open{
                transform:translateX(0%);
            }
            
            #mobile_nav{
                background:hsl(var(--_hue),var(--_saturation), var(--_lightness));
                display: block;
                width: 100%;
                min-height: calc(100vh - var(--ai-menu-drawer-top, 0px));
                /* min-height:100%; */
                
            }
            #mobile_nav_top{
                background:var(--ai-menu-drawer-top-background, hsl(var(--_hue),calc(var(--_saturation) - 5%), calc(var(--_lightness) - 20%)));
                padding:var(--ai-menu-drawer-top-padding, .5em);
            }
            .close_nav_btn{
                border:none;
                
                position: relative;
                display: block;
                margin:0px 0px 0px auto;
                color:var(--ai-menu-drawer-text-color, #222);
                background:none;
                font-size:40px;
                font-weight: 700;
            }
            ::slotted(a){
                
                display: block;
                padding:.8em 0;
                text-align: center;
                border:0px solid var(--ai-menu-drawer-link-border, rgba(0,0,0,.2));
                border-bottom-width:1px;
                color:var(--ai-menu-drawer-text-color, #222);
                text-decoration:none;
            }
        </style>
        <div id='mobile_nav_wrapper'>
            <div id="mobile_nav_overlay"></div>
            <div id="mobile_nav_container">
                <div id="mobile_nav">
                    <div id="mobile_nav_top">
                        <button class="close_nav_btn" on-click="close">&times;</button>
                    </div>
                    <slot></slot>
                </div>
            </div>
        </div>
        `;
    }
    static get properties() {
        return {
            version : {
                type : String,
                value : '0.5',
                readonly : true
            },
        };
    }
    constructor(){
		super();
		this._touchTracking = [];
		this._lastTouches = {};
        this._translateX = 0;
        this.removeAttribute('unresolved');
	}

	connectedCallback(){
		super.connectedCallback();
		this.attached = true;//tracks the attached state
		this._navContainer = this.shadowRoot.querySelector("#mobile_nav_container");
		this._overlay = this.shadowRoot.querySelector("#mobile_nav_overlay");
		this._overlayClick = this._overlayClick.bind(this);
		this._attachEventListeners();
    }
    
    ready(){
        super.ready();
        commandQueueConsume('ai-menu-drawer', (el, evt) => {
            this.opened = ! this.opened;
        }, this);
    }

	_attachEventListeners(){
		//overlay click to close
		this._overlayClick = this._overlayClick.bind(this);
		this._overlay.addEventListener('click', this._overlayClick);
		//bind touch event tracking for swipe closed
		this._touchmoveHandler = this._touchmoveHandler.bind(this);
		this.addEventListener('touchmove', this._touchmoveHandler);
		this._overlay.addEventListener('touchmove', this._touchmoveHandler);
		this._touchstartHandler = this._touchstartHandler.bind(this);
		this.shadowRoot.addEventListener('touchstart', this._touchstartHandler);
		this._touchendHandler = this._touchendHandler.bind(this);
		this.shadowRoot.addEventListener('touchend', this._touchendHandler);
	}

	_overlayClick(e){
		this.closed = true;
	}

	_touchstartHandler(e){
		if(this.opened){
			for(var i = 0; i < e.changedTouches.length; i++){
				if(! this._touchTracking.includes(e.changedTouches[i].identifier)){
					this._touchTracking.push(e.changedTouches[i].identifier);
					this._lastTouches[e.changedTouches[i].identifier] = e.changedTouches[i];
				}
			}
			//disable transition
			
		}
    }
    /**
     * reacts to touch motion and updates the tracking variables
     * @param {TouchEvent} e TouchMoveEvent
     * 
     * @todo lock-x axis when y-axis gesture?
     */
	_touchmoveHandler(e){
		if(this._touchTracking.length > 0){
			var primaryTouch = this._touchTracking[0];
			var touch = Array.prototype.slice.call(e.changedTouches).filter(function(item){
				return item.identifier == primaryTouch;
			})[0];
			var lastRecord = this._lastTouches[touch.identifier];
            this._translateX -= lastRecord.screenX - touch.screenX;
            this._translateX = Math.max(this._translateX, 0);
		}
		for(var i = 0; i < e.changedTouches.length; i++){
			//update last touches for all touches
			this._lastTouches[e.changedTouches[i].identifier] = e.changedTouches[i];
		}
        //update the position of the nav menu
        if( this._translateX > 15){
            if(! this.inDrag){
                this.inDrag = true;
                if(this._originalNavTransition === undefined) this._originalNavTransition = this._navContainer.style.transition;
                this._navContainer.style.transition = false;
            }
        }
        if(this.inDrag)
        this._navContainer.style.transform = "translateX(" + this._translateX + "px)";
	}

	_touchendHandler(e){
		for(var i = 0; i < e.changedTouches.length; i++){
			var cutIndex = this._touchTracking.indexOf(e.changedTouches[i].identifier);
			this._touchTracking = Array.prototype.concat(
				this._touchTracking.slice(0, cutIndex),
				this._touchTracking.slice(cutIndex + 1)
			);
			delete this._lastTouches[e.changedTouches[i].identifier];
		}
		if(this._touchTracking.length === 0){
			//no more touches
			if(this.inDrag){
				this._navContainer.style.transition = this._originalNavTransition;
				this._navContainer.getBoundingClientRect();//Force syncronous layout
				this._navContainer.style.transform = null;
				if(this._translateX > 200){
					this.close();
				}
				this._translateX = 0;
				this.inDrag = false;
			}
		}
	}

	
	_open(){
		if(! this.attached) return;
		this.opened = true;
		this._overlay.style.display = "block";
		this._overlay.style.opacity = 0;
		let _overlay = this._overlay;
		requestAnimationFrame(function(){
			requestAnimationFrame(function(){
				_overlay.style.opacity = 1;
			});
		});
		this._navContainer.classList.add('open');
	}

	_close(){
        if(! this.attached) return;
		this.opened = false;
        // this._navContainer.style.transition = this._originalNavTransition;
		// this._navContainer.getBoundingClientRect();//Force syncronous layout
		this._navContainer.style.transform = null;
        this._navContainer.classList.remove('open');
		this._overlay.style.opacity = 0;
		let fireOnce = function(){
            this._overlay.style.display = "none";
			this._overlay.removeEventListener('transitionend', fireOnce);
		};
		fireOnce = fireOnce.bind(this);
		this._overlay.addEventListener('transitionend',fireOnce);        
	}
}

window.customElements.define(AiMenuDrawer.is, AiMenuDrawer);
