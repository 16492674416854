import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';
import { commandQueueEnqueue } from './util/commandQueue.js';
import { dispatchEvent } from './util/eventsHelper.js';
/**
* `ai-trigger-nav-icon`
* 
* 
* @customElement
* @polymer
*/
class AiTriggerNavIcon extends PolymerElement {
	static get is(){ return 'ai-trigger-nav-icon'; }
	static get template() {
	return html`
		<style>
			:host {
				display: block;
				width:1.875em;
				height:1.875em;
				font-size:16px;
			}
			button{
				font-size:1em;
				width:1.875em;
				height: 1.875em;
				position:relative;
				background:transparent;
				display: inline-block;
				border:none;
				color:currentColor;
			}
			#mobile-nav-bars{
				position: absolute;
				width:1.875em;
				height: .25em;
				top:50%;
				left:0;
				transform:translateY(-50%);
				background-color:var(--ai-trigger-nav-icon-color, currentColor);
			}
			#mobile-nav-bars::before, #mobile-nav-bars::after{
				content:'';
				position: absolute;
				width: 1.875em;
				height:.25em;
				left:0;
				background-color:var(--ai-trigger-nav-icon-color, currentColor);
			}
			#mobile-nav-bars::before{
				top:-0.5em;
			}
			#mobile-nav-bars::after{
				bottom:-0.5em;
			}
		</style>
		<button 
			id='moblie-nav-btn' 
			aria-label="open navigation button"
			on-click="clickHandler" >
			<div id='mobile-nav-bars'></div>
		</button>
		`;
	}
	static get properties() {
		return {
			version : {
				type : String,
				value : '0.5',
				readonly : true
			},
			triggerName : {
				type : String,
				value : 'ai-menu-drawer'
			},
			triggerType : {
				type : String,
				value : "commandQueue"
			}
		};
	}
	constructor(){
		super();
	}

	ready(){
		super.ready();
	}
	clickHandler(evt){
		if(this.triggerType == 'commandQueue'){
			commandQueueEnqueue(this.triggerName, this, evt);
		}else if(this.triggerType == 'event'){
			dispatchEvent(this.triggerName, this);
		}
	}
}

window.customElements.define(AiTriggerNavIcon.is, AiTriggerNavIcon);
