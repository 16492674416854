import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';
import {animationFrame, microTask} from '@polymer/polymer/lib/utils/async.js';
import { dispatchEvent } from './util/eventsHelper.js';
/**
* `ai-header`
* 
* 
* @customElement
* @polymer
*/
class AiHeader extends PolymerElement {
	static get is(){ return 'ai-header'; }
	static get template() {
		return html`
			<style>
				*{
					box-sizing:border-box;
				}
				:host {
					box-sizing:border-box;
					display: block;
					/* background-color:var(--ai-header-background-color, #f3f3f3); */
					background:var(--ai-header-background, #f3f3f3);
					max-width:100%;
					position:relative;
					z-index:100;
				}
				:host([sticky]){
					position:fixed;
					width:100%;
					top:0;
					left:0;
				}
				:host([condenses]){
					position:fixed;
					width:100%;
					/* padding:15px 0px; */
					top:0;
					left:0;
					transition:padding var(--ai-header-condenses-transition-duration, .4s);
				}
				:host([condensed][condenses]){
					/* padding:0; */
				}
				:host([reveals]){
					position:fixed;
					width:100%;
					/* padding:15px 0px; */
					top:0;
					left:0;
					transform: translateY(-102%);
					transition:transform var(--ai-header-reveals-transition-duration, .3s), padding var(--ai-header-reveals-transition-duration, .3s);
				}
				:host([revealed][reveals]){
					transform:translateY(0%);
				}
				:host([condensed][reveals]){
					/* padding:0; */
				}
				:host([beforeAttached]){
					transition:none;
				}
				:host([reveals]){

				}
				div{
					/*display:block;*/
					/*display:inherit;
					flex-direction:inherit;
					justify-content:inherit;
					align-items:inherit;*/
					display: var(--ai-header-content-display, block);
					justify-content: var(--ai-header-content-justify-content, initial);
					align-items: var(--ai-header-content-align-items, initial);
					float: var(--ai-header-content-float, initial);
					width:var(--ai-header-content-width, var(--ai-content-width, 1400px));
					max-width:var(--ai-header-content-max-width, 100%);
					margin:var(--ai-header-content-margin, 0 auto);
				}
			</style>
			<div>
				<slot></slot>
			</div>
			`;
		}
		static get properties() {
			return {
				version : {
					type : String,
					value : '0.6.0',
					readonly : true
				},
				condenses: {
					type: Boolean,
					value: false,
					reflectToAttribute: true,
					observer : '_condensesObserver'
				},
				condensed : {
					type : Boolean,
					value : false,
					reflectToAttribute : true
				},
				reveals: {
					type: Boolean,
					value: false,
					reflectToAttribute: true,
					observer : '_revealsObserver'
				},
				revealed : {
					type : Boolean,
					value : false,
					reflectToAttribute : true
				},
				scrollThreshold: {
					type: Number,
					value: 100
				}
			};
		}
	constructor(){
		super();
		this.lastScroll = window.pageYOffset;
	}

	connectedCallback(){
		super.connectedCallback();
		this.setAttribute('beforeAttached','');
		this.removeAttribute('unresolved');
	}
	ready(){
		super.ready();
		if(this.reveals)
			this.revealed = true;
		animationFrame.run(() => this.removeAttribute('beforeAttached'));		
	}

	_attachScrollListener(){
		this.scrollHandler = this.scrollHandler.bind(this);
		let scrollHandler = this.throttle(this.scrollHandler, 150, true);
		document.addEventListener('scroll', scrollHandler);		
	}

	static get observers(){
		return [
			'_behaviorObserver(reveals, condenses)'
		];
	}
	_behaviorObserver(reveals, condenses, sticks){
		if((reveals || condenses || sticks) && ! this._scrollBound){
			this._scrollBound = true;
			this._attachScrollListener();
		}
		this._updateScrollBehavior();
	}

	

	_updateScrollBehavior(){
		if(this.reveals){ //revealing behavior
			if(this.upwardScrollCheck() || window.pageYOffset < this.scrollThreshold){
				this.revealed = true;
				dispatchEvent('revealchange', this, {revealed : true});
			}else{
				this.revealed = false;
				dispatchEvent('revealchange', this, {revealed : false});
			}
		}
		if(this.condenses || this.reveals){
			if(window.pageYOffset > this.scrollThreshold){
				this.condensed = true;
				dispatchEvent('condensechange', this, {condensed : true});
			}else{
				this.condensed = false;
				dispatchEvent('condensechange', this, {condensed : false});
			}
		}else{
			this.revealed = false;
			this.condensed = false;
		}
	}

	scrollHandler(evt){
		this._updateScrollBehavior();
		this.lastScroll = window.pageYOffset;
	}

	upwardScrollCheck(){
		if(this.lastScroll > window.pageYOffset){
			return true;
		}
		return false;
	}

	throttle(fn, delay, tailEndInvoke = false){
		let timer = 0;
		let timeout = null;
		return (...args) => {
			clearTimeout(timeout);
			if(Date.now() >= timer + delay){
				timer = Date.now();
				fn(...args);
			}else if(tailEndInvoke){
				timeout = setTimeout(() => fn(...args), delay);
			}
		};
	}
}

window.customElements.define(AiHeader.is, AiHeader);
