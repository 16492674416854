import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';
import { afterNextRender } from '@polymer/polymer/lib/utils/render-status.js';
import { resizeObserverHandler } from './util/resizeObserverHandler.js';
// import './util/focus-visible.js';
/**
* `ai-reviews-slider`
* 
* 
* @customElement
* @polymer
*/
class AiReviewsSlider extends resizeObserverHandler(PolymerElement) {
    static get is(){ return 'ai-reviews-slider'; }
    static get template() {
    return html`
        <style>
            /*
            This will hide the focus indicator if the element receives focus via the mouse,
            but it will still show up on keyboard focus.
            */
            :host-context(body.js-focus-visible :not(.focus-visible)) :focus {
                /* outline: none; */
                outline:1px solid red;
            }
            :host {
                --_hue:var(--ai-review-slider-primary-hue, var(--ai-primary-color-hue, 230));
                --_saturation:var(--ai-review-slider-primary-saturation, var(--ai-primary-color-saturation, 15%));
                --_lightness:var(--ai-review-slider-primary-lightness, var(--ai-primary-color-lightness, 80%));
                display: block;
                box-sizing:border-box;
                width:100%;
                height:280px;
                height: 17em;
                position: relative;
                font-size:.7em;
                /* font-size:16px; */
            }
            :host([sm]){
                font-size:.7em;
            }
            :host([med]){
                font-size:.9em;
            }
            :host([lrg]){
                font-size:1em;
            }
            
            *{
                box-sizing:border-box;
            }
            #container{
                position: absolute;
                width: 100%;
                max-width: 100%;
                height:250px;
                height: 16em;
                top:50%;
                left:50%;
                transform:translate(-50%, -50%);
                
                overflow: hidden;
                /* overflow-x:auto; */
                /* display:flex; */
                /* scroll-snap-type: x mandatory; */
            }
            #container:before{
                content:'';
                position: absolute;
                top:50%;
                left: 0;
                width: 100%;
                height: 50%;
                background:#F15A24;
                transform:translateY(-50%);
            }
            :host([sm]) #container{
                width: 275px;
                width: 24em;
            }
            :host([med]) #container{
                width: 565px;
                width: 40em;
            }
            :host([lrg]) #container{
                width:920px;
                width: 58em;
                /* margin:auto; */
            }
            :host > button{
                display: inline-block;
                position: absolute;
                border:0px;
                border-radius:1000px;
                background-color: #fff;
                box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.4);
                width:50px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 2;
                opacity:.6;
                cursor: pointer;
                font-size:1em;
            }
            :host > button:hover{
                opacity: 1;
            }
            :host > button.hidden{
                visibility:hidden;
            }
            .arrow{
                width:15px;
                height: 15px;
                display: block;
                border-style:solid;
                border-color:#333;
                border-width:0px 2px 2px 0px;
            }
            #left .arrow{
                transform:translate(3px, 0px) rotate(135deg);
            }
            #right .arrow{
                transform:translate(-3px, 0px) rotate(-45deg);
            }
            
            #left{
                left:0px;
                top:50%;
                transform:translate(0%,-50%);
            }
            #right{
                right:0px;
                top:50%;
                transform:translate(0%,-50%);
            }
            :host([sm]) #left{
                left:calc((100% - 24em)/2);
            }
            :host([sm]) #right{
                right:calc((100% - 24em)/2);
            }
            :host([med]) #left{
                left:calc((100% - 43em)/2);
            }
            :host([med]) #right{
                right:calc((100% - 43em)/2);
            }
            :host([lrg]) #left{
                left:calc((100% - 61em)/2);
            }
            :host([lrg]) #right{
                right:calc((100% - 61em)/2);
            }
            .review_item{
                position: absolute;
                background:#fff;
                top: 5px;
                display:flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.4);
                padding:5px;
                border-radius:0;
                width: 90%;
                height: calc(100% - 10px);
                /* width:295px;
                width:18.2em;
                height: 240px;
                height: 15em; */
                /* margin:10px; */
                /* scroll-snap-align:start; */
            }
            :host([sm]) .review_item{
                width: calc(100% - 10px);
                width:295px;
                width:18.2em;
                height: 240px;
                height: 15em;
            }
            :host([med]) .review_item{
                
                width:calc(1/2 * 100% - 10px);
                width:295px;
                width:18.2em;
                height: 240px;
                height: 15em;
            }
            :host([lrg]) .review_item{
                width:calc(1/3 * 100% - 10px);
                width:295px;
                width:18.2em;
                height: 240px;
                height: 15em;
            }
           
            

            .review_name{
                margin:0 0 1em 0;
                margin-top: auto;
                font-size: 1em;
                color: #222;
            }
            .review_text{
            }
            .review_logo{
            }
            .logo{
                /* height: 2em; */
            }
            .logo>img{
                /* max-height:100%; */
                height: 1.5em;
                object-fit:contain;
                display: block;
            }
            .logo[review_source='survey'],.logo[review_source='internal']{
                background:var(--ai-reviews-slider-internal-logo-color, #555);
                border-radius:3px;
            }
            .logo[review_source='survey']>img,.logo[review_source='internal']>img{
                object-position:top;
            }
            .stars_container{
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .star{
                position: relative;
                width: 1em;
                height: 1em;
                margin:.3em;
                display:flex;
                align-items: flex-start;
            }
            .star>span{
                width: 100%;
                height: 100%;
                margin-right: -100%;
                background-repeat: no-repeat;
                background-size: cover;
            }
            .star>.empty{
                background-image: url('/assets/images/star_empty.svg');
            }          
            .star>.full{
                background-image: url('/assets/images/star_full.svg');
            }

            .star.three_quarter>.full{
                width: 67%;
            }
            .star.half>.full{
                width: 56%;
            }
            .star.quarter>.full{
                width: 40%;
            }
            .star.empty>.full{
                width: 0%;
            }
            

            .read_more{
                margin-top: auto;
                width: 100%;
                text-align: center;
            }
            .read_more:before{
                content:"";
                display: block;
                width: 70%;
                height: 1px;
                background-color: #E6E6E6;
                margin: 0 auto;
            }
            .read_more button{
                display: block;
                width: 100%;
                border:0px;
                border-radius:0px;
                background: none;
                padding: .6em 0 1.2em;
                text-decoration: none;
                font-size: 1.2em;
                color:#808080;
                box-sizing:none;
                cursor: pointer;
            }

            #details{
                display: none;
                position: absolute;
                flex-direction: column;

                padding: .7em 4em 1em 4em;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 3;
                box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.4);
                background:var(--ai-reviews-slider-details-background, hsl(var(--_hue), var(--_saturation), var(--_lightness)));
                color:#222;
            }
            :host([detail-opened]) #details{
                display: flex;
            }
            #details_close{
                position: absolute;
                top:2px;
                right:5px;
                border:none;
                background:none;
                border-radius:0px;
                font-size: 3em;
                line-height: 1;
                color: #222222;
            }
            #details_name{
                font-size: 1em;
                line-height: 1.2;
                margin: 0;
                padding: 0;
            }
            #details_stars{
                font-size: 1.4em;
            }
            #details_date{
                font-size: .7em;
                line-height: 1.2;
                font-weight: 700;
                margin: .4em 0em 1em 0em;
                padding: 0;
            }
            #details_content{
                font-size: 1em;
                white-space:pre-wrap;
                flex-grow:1;
                overflow-y:auto;
            }

            </style>
            <button id='left' aria-label="previous" class$="[[_showArrow(_leftArrow, '_leftArrow')]]"><span class='arrow'></span></button>
            <div id='container'></div>
            <button id='right' aria-label="next" class$="[[_showArrow(_rightArrow, '_rightArrow')]]"><span class='arrow'></span></button>
            <div id='details'>
                
                <div id='details_stars'>
                    <div class='stars_container'>
                        <div class='star'>
                            <span class='empty'></span>
                            <span class='full'><span>
                        </div>
                        <div class='star'>
                            <span class='empty'></span>
                            <span class='full'><span>
                        </div>
                        <div class='star'>
                            <span class='empty'></span>
                            <span class='full'><span>
                        </div>
                        <div class='star'>
                            <span class='empty'></span>
                            <span class='full'><span>
                        </div>
                        <div class='star'>
                            <span class='empty'></span>
                            <span class='full'><span>
                        </div>
                    </div>
                </div>
                <h4 id='details_name'></h4>
                <h5 id='details_date'></h5>
                <div id='details_content'>
                </div>
                <button id='details_close'>&times;</button></div>
            `;
        }
        static get properties() {
            return {
                version : {
                    type : String,
                    value : '0.6.1',
                    readonly : true
                },
                data : {
                    type : Object,
                    value : {}
                },
                prop1 : {
                    type : String,
                    value : 'ai-reviews-slider',
                },
                breakpoints : {
                    type : Object,
                    value : {
                        lrg : 920,
                        med : 600,
                        sm : 360
                    }
                },
                _leftArrow : {
                    type : Boolean,
                    value : true
                },
                _rightArrow : {
                    type : Boolean,
                    value : true
                },
                logos : {
                    type : Object,
                    value : {
                        "facebook" : "/assets/images/source_logos/facebook.png",
                        "foursquare" : "/assets/images/source_logos/foursquare.png",
                        "google" : "/assets/images/source_logos/google.png",
                        "merchantcircle" : "/assets/images/source_logos/merchantcircle.png",
                        "reviewpush" : "/assets/images/source_logos/reviewpush.png",
                        "sparefoot" : "/assets/images/source_logos/sparefoot.jpg",
                        "yelp" : "/assets/images/source_logos/yelp.png",
                        "survey" : "/assets/images/source_logos/internal.png",
                        "internal" : "/assets/images/source_logos/internal.png"
                    }
                },
                detailOpened : {
                    type : Boolean,
                    value : false,
                    reflectToAttribute : true
                }
            };
        }
    constructor(){
        super();
        this._items = [];
        this._leftOffset = 5;
        this._itemGutter = 10;
        this._offsetChange = 0;
        this._slide = 0;
        this._destinationSlide = 0;
        this._numSlides = 1;
        this._slideCount = {
            lrg : 3,
            med : 2,
            sm : 1
        };
        this.removeAttribute('unresolved');
    }

    ready(){
        super.ready();
        this._container = this.shadowRoot.querySelector('div');
        this._bindToSelf();
        this._attachEventListeners();
        afterNextRender(this, () => {
            this._createReviewsElements();
            this._offsetChange = this._container.getBoundingClientRect().width;
            this._itemWidth = this._items[0].getBoundingClientRect().width;// + this._itemGutter;
            this._updateSlideCount();
            this._updatePositions();
        });
    }

    _bindToSelf(){
        if(super._bindToSelf) super._bindToSelf();
        this._leftClickHandler = this._leftClickHandler.bind(this);
        this._rightClickHandler = this._rightClickHandler.bind(this);
        // this._readMoreHandler = this._readMoreHandler.bind(this);//bound per element
        this._readMoreCloseHandler = this._readMoreCloseHandler.bind(this);
    }

    _attachEventListeners(){
        this.$.left.addEventListener('click', this._leftClickHandler);
        this.$.right.addEventListener('click', this._rightClickHandler);
        this.$.details_close.addEventListener('click', this._readMoreCloseHandler);
    }

    _showArrow(show, arrow){
        return show ? "" : "hidden";
    }

    _resizeNotify(size){
        super._resizeNotify(size);
        
        afterNextRender(this, () => {
            if(this._items.length){
                this._updateSlideCount();
                let oldOffset = this._container.getBoundingClientRect().width / this._offsetChange;
                this._leftOffset *= oldOffset;
                this._offsetChange = this._container.getBoundingClientRect().width;
                this._itemWidth = this._items[0].getBoundingClientRect().width;// + this._itemGutter;
                this._itemGutter = (this._offsetChange - (this._numSlides * this._itemWidth))/this._numSlides;
                if((this._numSlides * this._slide) > this._items.length){
                    this._slide = Math.ceil(this._items.length/this._numSlides) - 1;
                }
                this._updatePositions();
            }
        });
    }

    _leftClickHandler(evt){
        this.slide(-1);
    }
    _rightClickHandler(evt){
        this.slide(1);
    }

    _updateSlideCount(){
        let count;
        let largest = 0;
        for(var k in this._slideCount){
            if(this.getAttribute(k) !== null && this.breakpoints[k] > largest){
                largest = this.breakpoints[k];
                count = this._slideCount[k];
            }
        }
        this._numSlides = count || 1;
    }

    _createReviewsElements(){
        if(!this.data.reviews || this.data.reviews.length === 0) return;

        this.data.reviews.forEach(review => {
            const revEl = this._createReviewDom(review);
            this._container.appendChild(revEl);
            this._items.push(revEl);
        })
        
    }

    _createReviewDom(review){
        
        if(! this._reviewTemplate){
            this._reviewTemplate = document.createElement('div');
            this._reviewTemplate.innerHTML = `
                <div class='review_item'>
                    <h3 class='review_name'></h3>
                    <div class='logo'></div>
                    <div class='stars_container'>
                    <div class='star'>
                    <span class='empty'></span>
                    <span class='full'><span>
                    </div>
                    <div class='star'>
                    <span class='empty'></span>
                    <span class='full'><span>
                    </div>
                    <div class='star'>
                    <span class='empty'></span>
                    <span class='full'><span>
                    </div>
                    <div class='star'>
                    <span class='empty'></span>
                    <span class='full'><span>
                    </div>
                    <div class='star'>
                    <span class='empty'></span>
                    <span class='full'><span>
                    </div>
                    </div>
                    <div class='review_text'></div>
                    <div class='read_more'><button aria-label='Read More'>Read More</button></div>
                </div>`;
        }
        
        const el = (this._reviewTemplate.cloneNode(true)).children[0];
        el.querySelector('.review_name').innerHTML = review.reviewer;
        
        const source = review.review_source.toLowerCase().replace(/ /g,'');
        el.querySelector('.logo').setAttribute('review_source', source);
        if(this.logos[source]){
            const img = document.createElement('img');
            img.src = this.logos[source];
            el.querySelector('.logo').appendChild(img);
        }
        
        const reviewSummary = this._truncateReviewText(review.review_text);
        const reviewDetail = review.review_text || "";
        const revTextEl = el.querySelector('.review_text');
        if(reviewSummary.length){
            revTextEl.querySelector('.review_text').innerHTML = review.review_text;
        }else{
            revTextEl.parentNode.removeChild(revTextEl);
        }
        const readMoreEl = el.querySelector('.read_more');
        
        if(reviewDetail.length && reviewSummary.length != reviewDetail.length){
            readMoreEl.addEventListener('click', this._readMoreHandler.bind(this,el));
        }else{
            // readMoreEl.parentNode.removeChild(readMoreEl);
            readMoreEl.children[0].style.visibility = 'hidden';
        }
        this._fillStars(el.querySelectorAll('.star'),review.rating);
        el.review = review;
        return el;
    }

    _truncateReviewText(reviewText){
        reviewText = reviewText || "";
        return "";
    }

    _updatePositions(){
        const slideToWindow = this._slide / this._numSlides;
        this._leftOffset = -slideToWindow * this._offsetChange + this._itemGutter/2;
        this._items.forEach((item,i) => {
            let pos = (this._itemWidth + this._itemGutter) * i + this._leftOffset;
            // let stagger = Math.max(pos - this._offsetChange, 0) * 5 + this._itemGutter;
            
            item.style.transform = `translateX(${(pos)}px)`;
        });
        if(this._slide == 0){
            this._leftArrow = false;
        }else{
            this._leftArrow = true;
        }
        if(this._slide == this._items.length - this._numSlides){
            this._rightArrow = false;
        }else{
            this._rightArrow = true;
        }
    }

    // slide(direction = -1){
    //     let start;
    //     let duration = 1000;
    //     let initialVal = this._leftOffset;
    //     let destinationVal = initialVal + (this._offsetChange * direction);
    //     let _anim = (timestamp) => {
    //         start = start || timestamp;
    //         let progress = (timestamp - start)/duration;
    //         progress = this._ease(progress);
    //         progress = Math.min(1, progress);
    //         this._leftOffset = initialVal + (destinationVal - initialVal) * progress;
    //         this._updatePositions();
    //         if(progress < 1){
    //             requestAnimationFrame(_anim);
    //         }
    //     };
    //     requestAnimationFrame(_anim);
    // }
    slide(direction = 1){
        
        direction *= this._numSlides;
        if(this._destinationSlide + direction + this._numSlides - 1 >= this._items.length){
            direction = this._items.length - this._numSlides - this._destinationSlide;
        }else if(this._destinationSlide + direction < 0){
            direction = 0 - this._destinationSlide;
        }
        if(this._nextAnimationFrame){
            if(direction != 0){
                cancelAnimationFrame(this._nextAnimationFrame);
            }else{
                //we're not changing the destination so let the previous animation complete
                return;
            }
        }
        this._destinationSlide += direction;
        let start;
        let duration = 1000;
        let initialVal = this._slide;
        let destinationVal = this._destinationSlide;
        let _anim = (timestamp) => {
            start = start || timestamp;
            let progress = (timestamp - start)/duration;
            progress = this._ease(progress);
            progress = Math.min(1, progress);
            this._slide = initialVal + (destinationVal - initialVal) * progress;
            if(progress < 1){
                this._nextAnimationFrame = requestAnimationFrame(_anim);
            }else{
                this._slide = Math.round(this._slide);
                this._nextAnimationFrame = null;
            }
            this._updatePositions();
        };
        this._nextAnimationFrame = requestAnimationFrame(_anim);
    }

    _ease(i){
        return 1 - Math.pow(1 - i, 3);
    }

    _readMoreHandler(el, evt){
        if(! this.detailOpened){
            this.$.details_content.innerHTML = el.review.review_text;
            this.$.details_name.innerHTML = el.review.reviewer;
            this.$.details_date.innerHTML = el.review.ago_text;
            this._fillStars(this.$.details_stars.querySelectorAll('.star'), el.review.rating);
            this.detailOpened = true;
        }
    }
    _readMoreCloseHandler(evt){
        this.detailOpened = false;
    }

    _fillStars(starEls, val){
        for(let i = 0; i < 5; i++){
            starEls[i].classname = 'star';
            
            const star = i + 1;
            const diff = val - star;
            if(diff <= -1){
                starEls[i].classList.add('empty');
            }else if(diff <= -.75){
                starEls[i].classList.add('quarter');
            }else if(diff <= -.5){
                starEls[i].classList.add('half');
            }else if(diff <= -.25){
                starEls[i].classList.add('three_quarter');
            }else{
                starEls[i].classList.add('full');
            }
        }
    }

}

window.customElements.define(AiReviewsSlider.is, AiReviewsSlider);
