import { dedupingMixin } from "@polymer/polymer/lib/utils/mixin";
import { commandQueueEnqueue } from "./commandQueue.js";

/**
 * Establishes the resize pattern for AiComonents.
 * Works with ai-resize-pubsub pattern.
 * The extended class may expose a _resizeNotify method to handle resize events
 * 
 * @param {Class} superclass the class to be extended
 */
const resizeObs = function(superclass){
    return class extends superclass{
        static get properties(){
            return {
                breakpoints : {
                    type : Object,
                    value : {}
                }
            };
        }

        constructor(){
            super();
            this._resizeNotify = this._resizeNotify.bind(this);
        }
        connectedCallback(){
            super.connectedCallback();
        }
        ready(){
            super.ready();
            this._resizeObserverPromise = commandQueueEnqueue('ai-resize-pubsub', this, this._resizeNotify);//subscribe to ai-resize-pubsub singleton
            this._resizeObserverPromise.then((theResizeObserver) => {
                this._resizeObserver = theResizeObserver;
            });
        }

        /**
         * Invoked by the ai-resize-pubsub or resize observation.
         * 
         * @param {resizeObserverEntry} size the resize observer value
         */
        _resizeNotify(size){
            this._applyBreakpoints(size);
        }
        
        /**
         * Maintain a list of attributes on the component for every breakpoint smaller than the
         * component's current width on screen.
         * 
         * @param {resizeObserverEntry} size the resize observer value
         */
        _applyBreakpoints(size){
            let largestSize = 0;
            let largestKey = null;
            Object.entries(this.breakpoints).forEach(([key, value]) => {
                if(size.width < value){
                    this.removeAttribute(key);
                    this[key] = false;
                }else{
                    this.setAttribute(key, '');
                    this[key] = true;
                    if(value > largestSize){
                        largestSize = value;
                        largestKey = key;
                    }
                }
            });
            return largestKey;
        }
        
    };
}

export const resizeObserverHandler = dedupingMixin(resizeObs);